<template>
  <!-- <client-only> -->
  <section class="container2">
    <Swiper
      :pagination="{
        clickable: true,
      }"
      :autoplay="sliderAutoplay"
      :modules="modules"
      :slides-per-view="1"
      :space-between="5"
      class="h-auto"
      :class="pageClass"
    >
      <SwiperSlide v-for="item in data?.slides" :key="item.id">
        <!-- <NuxtLink
          class="flex h-full flex-col"
          :class="{ 'flex-col-reverse': data.bottomMobile }"
          :to="item?.buttonUrl"
        > -->
        <div
          class="flex h-full flex-col"
          :class="{ 'flex-col-reverse': data.bottomMobile }"
          @click="onClick(item?.buttonUrl)"
        >
          <div class="h-[625px] cursor-pointer lg:h-[600px]">
            <UiLazyImg
              class="h-full w-full"
              :src="item?.file?.url || ''"
              :src-mobile="item?.fileMobile?.url || ''"
              :alt="
                item?.file?.alt ||
                item.title ||
                removeHTMLTagFromString(item.text) ||
                'Image'
              "
              cover
              :no-lazy="!blockIndex"
            />
          </div>
          <div class="w-full">
            <div
              class="absolute inset-0 flex h-full items-center justify-end justify-center py-4 max-lg:container lg:pl-[181px] lg:pr-18"
            >
              <div class="w-full" @click="contentLink" v-html="item.text" />
            </div>
          </div>
        </div>
        <!-- </NuxtLink> -->
      </SwiperSlide>
    </Swiper>
  </section>
  <!-- </client-only> -->
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";
import { BlockSliderContent } from "@/types/api/block.types";
import "swiper/css/pagination";
import { useAppBreakpoints } from "~/composables";

const route = useRoute();
const router = useRouter();
const localePath = useLocalePathPolyfill();
const contentLink = useContentLink;
const props = withDefaults(
  defineProps<{
    data: BlockSliderContent;
    blockIndex?: number;
  }>(),
  {
    blockIndex: -1,
  },
);

const modules = [Pagination, Autoplay];

const pageClass = computed(() => {
  const slug = route.path.slice(1)?.split("/")?.join("-") || "home";
  return slug + "-" + props.blockIndex;
});

const sliderAutoplay = computed(() => {
  return false;
});

function onClick(url?: string) {
  if (url) {
    router.push(localePath(url));
  }
}
</script>

<style scoped>
.swiper :deep(.swiper-pagination) {
  @apply absolute bottom-2.5;
}
.swiper :deep(.swiper-pagination-bullet) {
  @apply mx-2 h-2 w-2 border border-transparent !bg-white opacity-100 transition-all lg:!bg-gray-dark;
}
.swiper :deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  @apply relative h-2.5 w-6 !rounded-md;
}

.swiper
  :deep(.swiper-pagination-bullet.swiper-pagination-bullet-active)::after {
  content: "";
  border-radius: 10px;
  display: block;
  width: 30px;
  height: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @apply border border-white transition-all lg:border-gray-dark;
}
</style>
